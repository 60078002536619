// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_q6 d_gs d_cr";
export var heroHeaderCenter = "t_gt d_gt d_cr d_dv";
export var heroHeaderRight = "t_gv d_gv d_cr d_dw";
export var heroParagraphLeft = "t_q7 d_gp d_cv";
export var heroParagraphCenter = "t_gq d_gq d_cv d_dv";
export var heroParagraphRight = "t_gr d_gr d_cv d_dw";
export var heroBtnWrapperLeft = "t_q8 d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "t_q9 d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "t_rb d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "t_rc d_gn d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "t_rd d_gm d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "t_rf w_rf";
export var heroExceptionNormal = "t_rg w_rg";
export var heroExceptionLarge = "t_rh w_rh";
export var Title1Small = "t_rj w_rj w_rG w_rH";
export var Title1Normal = "t_rk w_rk w_rG w_rJ";
export var Title1Large = "t_rl w_rl w_rG w_rK";
export var BodySmall = "t_rm w_rm w_rG w_rZ";
export var BodyNormal = "t_rn w_rn w_rG w_r0";
export var BodyLarge = "t_rp w_rp w_rG w_r1";