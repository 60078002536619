// extracted by mini-css-extract-plugin
export var alignLeft = "B_p5 d_fn d_bF d_dt";
export var alignCenter = "B_bN d_fp d_bC d_dv";
export var alignRight = "B_p6 d_fq d_bG d_dw";
export var element = "B_t2 d_cr d_cf";
export var customImageWrapper = "B_p8 d_cr d_cf d_Y";
export var imageWrapper = "B_t3 d_cr d_Y";
export var masonryImageWrapper = "B_pS";
export var gallery = "B_t4 d_v d_by";
export var width100 = "B_v";
export var map = "B_t5 d_v d_G d_Y";
export var quoteWrapper = "B_gN d_bB d_bN d_cr d_cf d_dt";
export var quote = "B_t6 d_bB d_bN d_dt";
export var quoteBar = "B_pL d_G";
export var quoteText = "B_pM";
export var customRow = "B_p0 d_bC d_Y";
export var separatorWrapper = "B_t7 d_v d_by";
export var articleText = "B_pp d_cr";
export var videoIframeStyle = "B_pG d_d4 d_v d_G d_bx d_b0 d_Q";