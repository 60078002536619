// extracted by mini-css-extract-plugin
export var customText = "p_p7 d_dt d_cr d_cf";
export var videoIframeStyle = "p_pG d_d4 d_v d_G d_bx d_b0 d_Q";
export var customImageWrapper = "p_p8 d_cr d_cf d_Y";
export var customRow = "p_p0 d_bC d_bd";
export var quoteWrapper = "p_gN d_bB d_bN d_cr d_cf";
export var quoteBar = "p_pL d_G";
export var masonryImageWrapper = "p_pS";
export var title = "p_p9";
export var Title3Small = "p_qb w_qb w_rG w_rP";
export var Title3Normal = "p_qc w_qc w_rG w_rQ";
export var Title3Large = "p_qd w_qd w_rG w_rR";