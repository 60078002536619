// extracted by mini-css-extract-plugin
export var tileContent = "v_rq d_v d_G d_Y";
export var teamTextLeft = "v_rr d_dt";
export var teamTextCenter = "v_rs d_dv";
export var teamTextRight = "v_rt d_dw";
export var alignLeft = "v_p5 d_v d_by d_fn d_bF d_dt";
export var alignCenter = "v_bN d_v d_by d_fp d_bC d_dv";
export var alignRight = "v_p6 d_v d_by d_fq d_bG d_dw";
export var teamContainer = "v_rv d_dV";
export var teamContainerFull = "v_rw d_dS";
export var teamRowWrapper = "v_rx d_cb";
export var teamTileWrapper = "v_j1 d_j1 d_Y d_ct";
export var teamTileSquareWrapper = "v_ry d_j2 d_Y d_ct";
export var teamTileRoundWrapper = "v_j2 d_j2 d_Y d_ct";
export var teamTileNoGuttersWrapper = "v_j3 d_j3 d_Y";
export var teamHoverNoGutters = "v_j4 d_j4 d_Z d_v d_G d_by d_bC d_bN d_bl";
export var teamImageText = "v_j9 d_j9 d_bk d_v d_bx";
export var teamInfoWrapperSquare = "v_rz d_j5";
export var teamInfoWrapperRound = "v_j5 d_j5";
export var teamInfoWrapper = "v_rB d_j6 d_Z";
export var teamInfoWrapperNoGutters = "v_j7 d_j7 d_v d_G d_bC d_bL d_bH d_c6";
export var teamImgWrapper = "v_jX d_jX";
export var teamImgWrapperAlt = "v_jY d_jY";
export var teamImgWrapperNoGutters = "v_j8 d_j8";
export var teamHeader = "v_rC d_cv";
export var teamHeaderAlt = "v_rD d_cv";
export var teamHeaderNoGutters = "v_rF d_cv d_cC";