// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "s_jN d_jN d_ct";
export var galleryMasonryImage = "s_jM d_jM d_v d_bQ d_dz";
export var alignLeft = "s_p5 d_fn d_bF d_dt";
export var alignCenter = "s_bN d_fp d_bC d_dv";
export var alignRight = "s_p6 d_fq d_bG d_dw";
export var galleryContainer = "s_qZ d_dV";
export var galleryContainerFull = "s_q0 d_dS";
export var galleryRowWrapper = "s_q1 d_cb";
export var galleryGuttersImage = "s_jQ d_jQ d_J d_ct";
export var galleryNoGuttersImage = "s_jP d_jP d_J d_cC";
export var galleryTextGutters = "s_jK d_jK d_cv";
export var galleryTextNoGutters = "s_jL d_jL d_cv";
export var galleryTextMasonry = "s_q2 d_jK d_cv";
export var galleryImageWrapper = "s_q3 d_ff d_Y";
export var descText = "s_q4 d_jR d_Z d_8 d_6 d_7 d_m";
export var guttersDesc = "s_q5";