// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_qr d_fn d_bF d_dt";
export var alignLeft = "r_p5 d_fn d_bF d_dt";
export var alignDiscCenter = "r_qs d_fp d_bC d_dv";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignDiscRight = "r_qt d_fq d_bG d_dw";
export var alignRight = "r_p6 d_fq d_bG d_dw";
export var footerContainer = "r_qv d_dV d_bV";
export var footerContainerFull = "r_qw d_dS d_bV";
export var footerHeader = "r_kc d_kc";
export var footerTextWrapper = "r_qx d_v";
export var footerDisclaimerWrapper = "r_kk d_kk d_cj";
export var badgeWrapper = "r_qy d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "r_qz undefined";
export var footerDisclaimerLeft = "r_qB undefined";
export var verticalTop = "r_qC d_by d_bH d_bM d_bK";
export var firstWide = "r_qD";
export var disclaimer = "r_qF d_by d_bH";
export var socialDisclaimer = "r_qG";
export var left = "r_qH";
export var wide = "r_qJ d_cx";
export var right = "r_qK d_bJ";
export var line = "r_fj d_fk d_ct";
export var badgeDisclaimer = "r_qL d_bB d_bN d_bH";
export var badgeContainer = "r_qM d_by d_bG d_bN";
export var badge = "r_qN";
export var padding = "r_qP d_c6";
export var end = "r_qQ d_bG";
export var linkWrapper = "r_qR d_dz";
export var link = "r_mz d_dz";
export var colWrapper = "r_qS d_cw";
export var consent = "r_f d_f d_bB d_bN";
export var media = "r_qT d_bw d_dx";
export var itemRight = "r_qV";
export var itemLeft = "r_qW";
export var itemCenter = "r_qX";
export var exceptionWeight = "r_qY w_r6";